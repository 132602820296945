<template>
  <div class="BlackListShopMember" @click="num=0">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/shop' }">门店管理</el-breadcrumb-item>
      <el-breadcrumb-item>黑名单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <div style="display: flex;">
        <el-button
          type="primary"
          size="small"
          style="background: #4781d9; border: 0; margin-right: 10px"
          @click.stop="addBlackListShopMember"
          >新增+</el-button
        >
        <el-upload
          class="upload-demo"
          :action="httpUrl + 'api/v2/chain/blackNameImport'"
          :headers="{ Authorization: 'Bearer ' + tk }"
          :before-upload="beforeAvatarUpload"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :show-file-list="false"
          accept=".xls,.xlsx"
        >
          <el-button
            size="small"
            type="primary"
            style="background: #4781d9; border: 0; margin-right: 10px"
            class="btn-item"
            ><span>批量导入</span><i class="iconfont icon-piliangdaoru"></i
          ></el-button>
        </el-upload>
        <a :href="imgUrl+'template/black_template.xlsx'" style="text-decoration: none">
          <el-button
            type="primary"
            size="small"
            style="background: #4781d9; border: 0"
            class="btn-item"
            ><span>模板下载</span><i class="iconfont icon-xiazai"></i
          ></el-button>
        </a>
      </div>
      <el-form
        ref="form"
        :model="memberForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="姓名">
          <el-input
            v-model="memberForm.memberName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" style="margin-left: 150px">
          <el-input v-model="memberForm.phone" style="width: 260px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click.stop="search"
            >搜索</el-button
          >
          <el-button @click.stop="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          type="index"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="id" label="ID"/>
          <el-table-column prop="name" label="姓名"/>
          <el-table-column prop="phone" label="手机"/>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div style="position: relative; display: inline">
                <i
                  style="color: #3ca6f0"
                  @click.stop="operation(scope.row)"
                  class="iconfont icon-bianji"
                >
                </i>
                <div v-if="scope.row.num == num" class="operation">
                  <p @click.stop="change" :class="active == 1 ? 'active' : ''">
                    编辑
                  </p>
                  <p @click.stop="del">删除</p>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-num">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="prev,pager,next, total,sizes,jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
    <div class="add-box" v-if="addShow" @click.stop="addShow = false">
      <div class="add-Member" @click.stop.stop="">
        <div style="display: flex; justify-content: space-between">
          <div class="add">新增</div>
          <div class="close" @click.stop="addShow = false">x</div>
        </div>
        <el-form
          :model="BlackListShopMemberFrom"
          ref="numberValidateForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="店员姓名"
            prop="name"
            :rules="[{ required: true, message: '店员姓名不能为空' }]"
          >
            <el-input
              size="small"
              type="age"
              v-model.number="BlackListShopMemberFrom.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号码"
            prop="phone"
            :rules="[{ required: true, message: '手机号码不能为空' }]"
          >
            <el-input
              size="small"
              type="age"
              v-model.number="BlackListShopMemberFrom.phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="display: flex; justify-content: flex-end; margin-top: 300px"
          >
            <el-button @click.stop="resetForm()" size="small">重置</el-button>

            <el-button
              type="primary"
              @click.stop="submitForm()"
              size="small"
              style="background: #cd0550; border: none"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="add-box" v-if="isShow" @click.stop="isShow = false">
      <div class="add-Member" @click.stop.stop="">
        <div style="display: flex; justify-content: space-between">
          <div class="add">编辑</div>
          <div class="close" @click.stop="isShow = false">x</div>
        </div>
        <el-form
          :model="changeMemberFrom"
          ref="numberValidateForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item
            label="店员姓名"
            prop="name"
            :rules="[{ required: true, message: '店员姓名不能为空' }]"
          >
            <el-input
              size="small"
              type="age"
              v-model.number="changeMemberFrom.name"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="手机号码"
            prop="phone"
            :rules="[{ required: true, message: '手机号码不能为空' }]"
          >
            <el-input
              size="small"
              type="age"
              v-model.number="changeMemberFrom.phone"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="display: flex; justify-content: flex-end; margin-top: 300px"
          >
            <el-button @click.stop="changeMemberFrom={}" size="small">重置</el-button>

            <el-button
              type="primary"
              @click.stop="changeMember()"
              size="small"
              style="background: #cd0550; border: none"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {getBlackListShopMemberList_api, addBlacListShopMemder_api ,changeBlacListShopMemder_api, deleteBlacListShopMember_api } from "@/api/shop.js"
import { imgUrl, httpUrl } from "@/utils/http.js"
import local from "@/utils/local.js"
import { Loading } from "element-ui"
export default {
  data() {
    return {
      imgUrl: imgUrl,
      httpUrl: httpUrl,
      tk: "",
      changeMemberFrom: {
        name: "",
        phone: "",
      },
      BlackListShopMemberFrom: {
        name: "",
        phone: "",
      },
      memberForm: {
        memberName: "",
        phone: "",
      },
      currentPage: 1,
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      tableData: [],
      num: 0,
      active: 1,
      addShow: false,
      isShow: false,
    };
  },
  methods: {
    //重置
    emptySearch() {
      this.memberForm = {}
      this.currentPage=1
      this.getBlackListShopMemberList()
    },
    //搜索
    search() {
      this.currentPage=1
      this.getBlackListShopMemberList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getBlackListShopMemberList()
    },
    //切换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getBlackListShopMemberList()
    },
    //显示操作页面
    operation(row) {
      if (this.num == row.num) {
        this.num = 0
      } else {
        this.num = row.num
      }
      this.memberId=row.id
      this.changeMemberFrom.name=row.name
      this.changeMemberFrom.phone=row.phone
    },
    //获取门店店员列表
    async getBlackListShopMemberList() {
      let res = await getBlackListShopMemberList_api({
        page: this.currentPage,
        limit: this.pageSize,
        name: this.memberForm.memberName,
        phone: this.memberForm.phone
      })
      this.total = res.data.data.total_count
      this.tableData = res.data.data.list
      this.tableData.forEach((item, index) => {
        item.num = index + 1
      })
    },
    //添加店员
    addBlackListShopMember() {
      this.addShow = true
    },
    //添加店员重置
    resetForm() {
      this.BlackListShopMemberFrom = {}
    },
    //添加店员提交
    async submitForm() {
      if (this.BlackListShopMemberFrom.name && this.BlackListShopMemberFrom.phone) {
        let res = await addBlacListShopMemder_api({
          name: this.BlackListShopMemberFrom.name,
          phone: this.BlackListShopMemberFrom.phone
        })
        if (res.data.code == 0) {
          this.$message({
            message: "添加成功!",
            type: "success"
          })
          this.getBlackListShopMemberList()
          this.addShow = false;
          this.BlackListShopMemberFrom.name=''
          this.BlackListShopMemberFrom.phone = ''
        }
      }
    },
    //编辑店员信息
    change() {
      this.isShow = true
      this.num = 0
    },
    //修改店员信息
    async changeMember(){
      let res=await changeBlacListShopMemder_api({
        member_id:this.memberId,
        name:this.changeMemberFrom.name,
        phone:this.changeMemberFrom.phone
      })
      if (res.data.code == 0) {
          this.$message({
            message: "修改成功!",
            type: "success"
          })
          this.getBlackListShopMemberList()
          this.isShow = false
        }
    },
    //删除店员信息
    async del(){
     let res=await deleteBlacListShopMember_api({
       member_id:this.memberId
     })
     if(res.data.code==0){
       this.$message({
          message: "修改成功!",
          type: "success"
        })
        this.getBlackListShopMemberList()
        this.num = 0
     }
    },
     //上传
     beforeAvatarUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isLt1M) {
        this.$message.error("上传文件大小不能超过 1MB!")
      } else {
        this.loadingInstance = Loading.service({
          text: "文件上传中,请稍后~",
        })
      }
      return isLt1M
    },
    //上传成功
    uploadSuccess(res) {
      this.loadingInstance.close()
      if (res.code == 0) {
        this.$message({
          message: "导入成功!",
          type: "success",
        })
        this.getBlackListShopMemberList()
      } else {
        this.$message({
          message: res.msg,
          type: "error",
        })
      }
    },
    //上传失败
    uploadError(res) {
      this.$message.error("导入失败,请检查格式.内容是否正确!")
      this.loadingInstance.close()
    },
  },
  created() {
    this.tk = local.get("tk")
    this.getBlackListShopMemberList()
  }
}
</script>

<style lang="less" scoped>
.BlackListShopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  z-index: 0;
}
.add-box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
.add-Member {
  width: 600px;
  height: 580px;
  background: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  box-sizing: border-box;
}
.close {
  cursor: pointer;
}
.add {
  border-bottom: #cd0550 3px solid;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #cd0550;
}
.demo-ruleForm {
  margin-top: 40px;
}
</style>